import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import { SiGotomeeting } from "react-icons/si";

const Contact = () => {
  return (
    <section id="contact">
      <h2> Contactez-moi</h2>
      <div className="contact__general">
        
        <div className="contact__content">
          <MdOutlineEmail className="contact__icon" />
          <h4>Email</h4>
          <h5>ldreflexo@gmail.com</h5>
          <a
            href="mailto:ldreflexo@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            Envoyer un message
          </a>
        </div>
        <div className="contact__content">
          <BsWhatsapp className="contact__icon" />
          <h4>WhatsApp</h4>
          <h5>+33 7 83 99 85 77</h5>
          <a
            href="https://api.whatsapp.com/send?phone=33679677598"
            target="_blank"
            rel="noreferrer"
          >
            Envoyer un sms
          </a>
        </div>
        <div className="contact__content">
          <SiGotomeeting className="contact__icon" />
          <h4>Liberlo</h4>
          <h5>LAURENCE DESCAT EI</h5>
          <a
            href="https://liberlo.com/profil/laurence-descat/"
            target="_blank"
            rel="noreferrer"
          >
            Prendre rdv en ligne
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
